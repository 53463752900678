import logo from './logo.svg';
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { MainPage } from './pages/MainPage/MainPage';
import { PageOne } from './pages/PageOne/PageOne';


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<MainPage></MainPage>} />
          <Route path="/one" element={<PageOne></PageOne>} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
