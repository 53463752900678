import React from 'react'

import styles from './MainPage.module.css'

import img1 from "../../assets/images/Главный банер.1.png"

import { Link } from 'react-router-dom'

export const MainPage = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.text}>
        <Link to="/one">Тюбик.</Link>
        <img src={img1} alt="" />
      </div>
    </div>
  );
}
