import React from "react";

import styles from "./PageOne.module.css";

import img1 from "../../assets/images/11tubik.jpg"

import { Link } from "react-router-dom";

export const PageOne = () => {
  return (
    <div className={styles.wrapper}>
      <div className={styles.navigator}>
        <Link>Подарки.</Link>
        <Link>Наборы.</Link>
        <Link>Сувениры.</Link>
        <a href="https://vk.com/tube_orsk">Контакты</a>
        <Link to={"/"}>На главную</Link>
      </div>
      <div className={styles.list}>
        <Link>
          Дизайнерам. <img src={img1} alt="" />
        </Link>
        <Link>
          Художнкам. <img src={img1} alt="" />
        </Link>
        <Link>
          Артистам. <img src={img1} alt="" />
        </Link>
        <Link>
          Архиологам. <img src={img1} alt="" />
        </Link>
        <Link>
          Детям. <img src={img1} alt="" />
        </Link>
        <Link>
          Музеям. <img src={img1} alt="" />
        </Link>
        <Link>
          Лепщикам. <img src={img1} alt="" />
        </Link>
        <Link>
          Фантазёрам. <img src={img1} alt="" />
        </Link>
      </div>
    </div>
  );
};
